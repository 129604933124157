import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Title from "../components/elements/title"
import SubTitle from "../components/elements/subtitle"
import Projects from "../components/projects"
import Reviews from "../components/Reviews"

const PortfolioPage = () => (
  <Layout>
    <SEO title="Proiecte" />
    <div className="section">
      <div className="container">
        <Title title="Povestea noastră" />
        <SubTitle subtitle="Preferăm să ne-o spunem prin muncă și prin experiențele pe care le-am creat:" />
      </div>
    </div>
    <Projects />
    <Reviews />
  </Layout>
)

export default PortfolioPage
